import { fromUnixTime, getUnixTime } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export function getDateForDatabase(date: string | Date) {
  const myTzOffset = new Date().getTimezoneOffset() * -60;

  const startTime = getUnixTime(new Date(date)) + myTzOffset;

  return fromUnixTime(startTime).toISOString();
}

export function getZeroedOutUtcDate(date: string | Date) {
  const newDate = new Date(date);

  newDate.setUTCHours(0, 0, 0, 0);

  return newDate.toISOString();
}

export function getDateWithTimezoneOffset(date: Date, timezoneOffset: number) {
  const myTzOffset = new Date().getTimezoneOffset() * -60;
  const theirTzOffset = timezoneOffset;
  const difference = myTzOffset - theirTzOffset;

  const adjustedDate = fromUnixTime(getUnixTime(new Date(date)) - difference);

  return adjustedDate;
}

export function getDateFromDatabase(date: string | Date) {
  return toZonedTime(date, "Etc/UTC");
}

export function getLocalDate(utcDate: string | Date | number) {
  let newDate = new Date(utcDate);

  const minutes = newDate.getTimezoneOffset();

  newDate = new Date(newDate.getTime() + minutes * 60000);

  return newDate;
}

export function getCurrentWeekStartDate() {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay());
  date.setHours(0, 0, 0, 0);
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      0,
      0,
      0,
    ),
  );
}
