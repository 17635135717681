import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import VerticalAlignTopRoundedIcon from "@mui/icons-material/VerticalAlignTopRounded";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  Portal,
  Typography,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import type { ActionItemWorkoutReview, Message } from "@trainwell/types";
import {
  differenceInCalendarDays,
  format,
  isFuture,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
} from "date-fns";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getDateWithTimezoneOffset } from "src/lib/date";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import {
  fetchFirstMessages,
  fetchMoreMessages,
  readChat,
  selectSelectedChat,
  updateChat,
} from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectTicketById } from "src/slices/ticketsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ChatMediaUpload } from "./ChatMediaUpload";
import ChatMessageBar from "./ChatMessageBar";
import ChatMessagesLoading from "./ChatMessagesLoading";
import MessageBubble from "./messages/MessageBubble";
import TicketMessageCard from "./messages/TicketMessageCard";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

type Props = {
  condensed?: boolean;
};

export default function ClientChat({ condensed }: Props) {
  const { ref, inView } = useInView();
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const clientBanner = useAppSelector((state) =>
    state.trainer.trainer?.banner_custom &&
    isFuture(state.trainer.trainer.banner_custom.date_active_until)
      ? state.trainer.trainer.banner_custom.text
      : state.client.client?.banner_coach?.active
        ? state.client.client?.banner_coach?.text
        : "",
  );
  const customBannerActiveUntilDate = useAppSelector((state) =>
    state.trainer.trainer?.banner_custom &&
    isFuture(state.trainer.trainer.banner_custom.date_active_until)
      ? state.trainer.trainer.banner_custom.date_active_until
      : null,
  );

  const trainer = useAppSelector(selectPrimaryTrainer);
  const shouldScroll = useRef(false);
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const ticketForChat = useAppSelector((state) =>
    selectedChat?.ticketId
      ? selectTicketById(state, selectedChat.ticketId)
      : undefined,
  );
  const mediaUploadUi = useAppSelector((state) => state.chat.mediaUploadUi);
  const isAuditMode = useAppSelector((state) => state.app.isAuditMode);

  const firstLoad = useRef(false);

  const messages = useMemo(
    () => selectedChat?.messages ?? [],
    [selectedChat?.messages],
  );
  const messageCount = messages.length;

  useEffect(() => {
    if (inView) {
      if (!firstLoad.current) {
        firstLoad.current = true;

        let index = messageCount - 1;

        if (
          selectedChat?.oldestUnreadMessageIdFromClient &&
          selectedChat.messages?.length
        ) {
          index = selectedChat.messages.findIndex(
            (m) =>
              m.message_id === selectedChat.oldestUnreadMessageIdFromClient,
          );
        }

        virtualizerRef.current?.scrollToIndex(index, {
          align: "start",
        });
      } else {
        loadMoreMessages();
      }
    }
  }, [inView]);

  const virtualizerRef = useRef<ReturnType<typeof useVirtualizer> | null>(null);

  const itemSize = 10;

  if (
    virtualizerRef.current &&
    messageCount !== virtualizerRef.current.options.count
  ) {
    if (shouldScroll.current) {
      shouldScroll.current = false;

      const delta = messageCount - virtualizerRef.current.options.count;
      const nextOffset =
        (virtualizerRef.current.scrollOffset ?? 0) + delta * itemSize;

      virtualizerRef.current.scrollOffset = nextOffset;
      virtualizerRef.current.scrollToOffset(nextOffset, { align: "start" });
      virtualizerRef.current.calculateRange();
      console.log("Chat: Adjusted scroll offset", nextOffset);
    } else {
      virtualizerRef.current?.scrollToIndex(messageCount - 1, {
        align: "start",
      });
    }
  }
  const parentRef = useRef(null);
  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    count: messageCount,
    estimateSize: () => itemSize,
    getItemKey: useCallback((index) => messages[index].message_id, [messages]),
    overscan: 25,
    scrollMargin: 50,
  });

  useIsomorphicLayoutEffect(() => {
    virtualizerRef.current = virtualizer;
  });

  const items = virtualizer.getVirtualItems();

  const [paddingTop, paddingBottom] =
    items.length > 0
      ? [
          Math.max(0, items[0].start - virtualizer.options.scrollMargin),
          Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end),
        ]
      : [0, 0];

  useEffect(() => {
    if (!selectedChat?.id) {
      return;
    }

    // dispatch(fetchFirstMessages(selectedChat.id));

    dispatch(
      updateChat({
        id: selectedChat?.id ?? "",
        firstMessageFetchState: "idle",
      }),
    );
  }, [dispatch, isAuditMode]);

  useEffect(() => {
    if (selectedChat?.firstMessageFetchState === "idle") {
      dispatch(fetchFirstMessages(selectedChat.id));
    }
  }, [selectedChat, dispatch]);

  useEffect(() => {
    if (
      selectedChat &&
      selectedChat.messages.length > 0 &&
      dashMode !== "programming"
    ) {
      dispatch(readChat(selectedChat.id));
    }
  }, [dispatch, selectedChat, trainer?.trainer_id, dashMode]);

  useEffect(() => {
    return () => {
      if (selectedChat?.id) {
        dispatch(
          updateChat({
            id: selectedChat.id,
            oldestUnreadMessageIdFromClient: undefined,
          }),
        );
      }
    };
  }, [selectedChat?.id, dispatch]);

  function scrollToTop() {
    if (virtualizerRef.current) {
      virtualizerRef.current.scrollToOffset(0, { align: "start" });
    }
  }

  function handleGoToTop() {
    if (selectedChat?.loadingState === "endReached") {
      scrollToTop();
    } else {
      shouldScroll.current = false;

      dispatch(
        fetchMoreMessages({
          chatId: selectedChat!.id,
          all: true,
        }),
      ).then(() => {
        scrollToTop();
      });
    }
  }

  function loadMoreMessages() {
    if (selectedChat?.loadingState === "loading") {
      return;
    }

    shouldScroll.current = true;

    dispatch(fetchMoreMessages({ chatId: selectedChat?.id ?? "" }));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
      }}
    >
      {clientBanner && (
        <Box
          sx={{
            px: 1,
            py: 0.5,
            backgroundColor: (theme) => theme.palette.warningSurface.main,
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              width: 19,
              height: 19,
              mr: 1,
            }}
          >
            <CalendarMonthRoundedIcon sx={{ fontSize: 13 }} />
          </Avatar>
          <Box>
            <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {clientBanner}
            </Typography>
            <Typography variant="overline">
              {customBannerActiveUntilDate !== null
                ? `Set by you until ${format(customBannerActiveUntilDate, "h:mm a")}`
                : "Set by trainwell"}
            </Typography>
          </Box>
        </Box>
      )}
      {selectedChat?.firstMessageFetchState === "done" ? (
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            flex: 1,
            contain: "strict",
          }}
          ref={parentRef}
        >
          <Box
            sx={{
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
            }}
            ref={ref}
          >
            {selectedChat.loadingState === "loading" ||
            selectedChat.loadingState === "succeeded" ? (
              <>
                <Typography sx={{ textAlign: "center" }}>
                  Loading more...
                </Typography>
                <LinearProgress sx={{ width: 200 }} />
              </>
            ) : selectedChat.loadingState === "endReached" ? (
              <Typography sx={{ textAlign: "center" }}>
                The very beginning
              </Typography>
            ) : selectedChat.loadingState === "failed" ? (
              <Button
                startIcon={<ErrorRoundedIcon />}
                onClick={() => {
                  loadMoreMessages();
                }}
              >
                Error loading messages. Try again
              </Button>
            ) : (
              <Button
                onClick={() => {
                  loadMoreMessages();
                }}
              >
                Load more
              </Button>
            )}
          </Box>
          {ticketForChat && (
            <Box sx={{ p: 1 }}>
              <TicketMessageCard chat={selectedChat} />
            </Box>
          )}
          <div
            style={{
              overflowAnchor: "none",
              paddingTop,
              paddingBottom,
            }}
          >
            {items.map((virtualRow) => {
              const message = messages[virtualRow.index];

              return (
                <VirtualMessage
                  key={virtualRow.key}
                  index={virtualRow.index}
                  ref={virtualizer.measureElement}
                  message={message}
                  trainerId={trainer?.trainer_id ?? ""}
                  condensed={condensed}
                />
              );
            })}
          </div>
          <Footer onGoToTop={handleGoToTop} />
        </div>
      ) : selectedChat ? (
        <ChatMessagesLoading />
      ) : null}
      {selectedChat && <ChatMessageBar />}
      {(mediaUploadUi === "show" || mediaUploadUi === "uploading") && (
        <Portal>
          <ChatMediaUpload />
        </Portal>
      )}
    </Box>
  );
}

type FooterProps = {
  onGoToTop: () => void;
};

function Footer({ onGoToTop }: FooterProps) {
  const selectedChat = useAppSelector(selectSelectedChat);
  const workoutActionItemCount = useAppSelector(
    (state) =>
      selectActionItemsForClient(state, selectedChat?.id ?? "").filter(
        (ai) =>
          ai.type === "workout_review" &&
          (ai as ActionItemWorkoutReview).is_internal,
      ).length,
  );

  return (
    <Box>
      {workoutActionItemCount > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              borderRadius: "50px",
              backgroundColor: (theme) => theme.palette.primary.main,
              px: 1,
              py: 0.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                textAlign: "center",
              }}
            >
              {workoutActionItemCount} unread workout
              {workoutActionItemCount > 1 ? "s" : ""}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          pb: 1,
          mx: 1,
        }}
      >
        <LoadingButton
          variant="text"
          size="small"
          startIcon={<VerticalAlignTopRoundedIcon />}
          onClick={onGoToTop}
          loading={selectedChat?.loadingState === "loading"}
        >
          Top
        </LoadingButton>
        <Box>
          {selectedChat?.clientTimezoneOffset !== undefined && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "right",
              }}
            >
              Client local time:{" "}
              {format(
                getDateWithTimezoneOffset(
                  new Date(),
                  selectedChat?.clientTimezoneOffset ?? 0,
                ),
                "hh:mm aaa",
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const VirtualMessage = memo(
  forwardRef(function VirtualMessage(
    {
      index,
      message,
      condensed,
      trainerId,
    }: {
      index: number;
      message: Message;
      condensed?: boolean;
      trainerId: string;
    },
    ref: React.Ref<HTMLDivElement>,
  ) {
    const selectedChat = useAppSelector(selectSelectedChat);
    const forceSmsDisabled = useAppSelector(
      (state) =>
        selectClientById(state, selectedChat?.id ?? "")?.settings.disable_sms ??
        false,
    );
    const isAuditMode = useAppSelector((state) => state.app.isAuditMode);

    if (!selectedChat) {
      return;
    }

    let showName = selectedChat.isGroupChat && message.from_id !== trainerId;

    const nextMessage = selectedChat?.messages.at(index + 1);
    const previousMessage =
      index === 0 ? undefined : selectedChat?.messages.at(index - 1);

    let previousTrainerMessage: Message | undefined = undefined;

    for (let i = index - 1; i >= 0; i--) {
      const message = selectedChat?.messages.at(i);
      if (message && message.from_id === trainerId) {
        previousTrainerMessage = message;
        break;
      }
    }

    const combineBottom = Boolean(
      nextMessage &&
        nextMessage.from_id === message.from_id &&
        nextMessage.type !== "notification",
    );

    if (
      showName &&
      previousMessage &&
      previousMessage.from_id === message.from_id
    ) {
      showName = false;
    }

    const fromMe = selectedChat.isTrainwell
      ? message.from_id === "copilot"
      : message.from_id === trainerId ||
        message.from_id === selectedChat.oldTrainerId ||
        (message.trainer_id_interim === trainerId &&
          message.from_id !== selectedChat.id);

    const actuallyFromThisCoach =
      !message.trainer_id_interim && message.from_id === trainerId;

    let dayMarkerText: string | null = null;

    if (
      previousMessage &&
      (isAuditMode ||
        differenceInCalendarDays(
          message.send_date,
          previousMessage.send_date,
        ) >= 1)
    ) {
      if (isToday(message.send_date)) {
        dayMarkerText = format(message.send_date, "'Today' h:mm aa");
      } else if (isYesterday(message.send_date)) {
        dayMarkerText = format(message.send_date, "'Yesterday' h:mm aa");
      } else if (isThisWeek(message.send_date)) {
        dayMarkerText = format(message.send_date, "EEEE h:mm aa");
      } else if (isThisYear(message.send_date)) {
        dayMarkerText = format(message.send_date, "MMM d h:mm aa");
      } else {
        dayMarkerText = format(message.send_date, "MMM d, yyyy h:mm aa");
      }
    }

    return (
      <Box ref={ref} data-index={index}>
        {dayMarkerText && (
          <Typography variant="overline" sx={{ textAlign: "center", py: 1 }}>
            {dayMarkerText}
          </Typography>
        )}
        {fromMe &&
          !message.read_date &&
          previousTrainerMessage?.read_date &&
          !selectedChat.isGroupChat && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                px: 2,
                py: 2,
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: (theme) => theme.palette.divider,
                  mr: 1,
                }}
              />
              <Typography
                variant="overline"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {selectedChat.clientName} read to here
              </Typography>
            </Box>
          )}
        {selectedChat.oldestUnreadMessageIdFromClient === message.message_id &&
          !selectedChat.isGroupChat && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                px: 2,
                py: 2,
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                New
              </Typography>
              <Box
                sx={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  ml: 1,
                }}
              />
            </Box>
          )}
        <MessageBubble
          message={message}
          isFromMe={fromMe}
          isFromOtherCoach={Boolean(
            fromMe &&
              !actuallyFromThisCoach &&
              (message.trainer_id_interim === selectedChat.oldTrainerId ||
                message.trainer_id_interim !== trainerId),
          )}
          combineBottom={combineBottom}
          forceSmsDisabled={forceSmsDisabled}
          showName={showName}
          condensed={condensed}
        />
      </Box>
    );
  }),
);
