import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useHabitDays } from "@trainwell/features";
import type { HabitDay, StreakState } from "@trainwell/types";
import {
  endOfDay,
  format,
  isToday,
  startOfDay,
  startOfWeek,
  subMonths,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { ClientStreak } from "../streaks/ClientStreak";
import { CompletedRestDayIcon } from "../streaks/CompletedRestDayIcon";
import { CompletedWorkoutIcon } from "../streaks/CompletedWorkoutIcon";
import { StreakBrokenIcon } from "../streaks/StreakBrokenIcon";
import { StreakFrozenIcon } from "../streaks/StreakFrozenIcon";
import { StreakInDangerIcon } from "../streaks/StreakInDangerIcon";
import { UpcomingRestDayIcon } from "../streaks/UpcomingRestDayIcon";
import { UpcomingWorkoutIcon } from "../streaks/UpcomingWorkoutIcon";

type Props = {
  sx?: SxProps<Theme>;
};

export function Consistency({ sx = [] }: Props) {
  const client = useAppSelector((state) => state.client.client);
  const habitDaysQuery = useHabitDays({
    userId: client?.user_id ?? "",
    dateStart: fromZonedTime(
      startOfDay(subMonths(new Date(), 1)),
      "Etc/UTC",
    ).toISOString(),
    dateEnd: fromZonedTime(endOfDay(new Date()), "Etc/UTC").toISOString(),
  });

  if (!client) {
    return <LoadingComponent message={"Loading your client 😄"} />;
  }

  if (!habitDaysQuery.data || habitDaysQuery.isPending) {
    return <LoadingComponent message={"Loading streak info"} />;
  }

  let pastDaysWithWorkoutsAssigned = habitDaysQuery.data.habit_days.filter(
    (day) =>
      day.streak.state === "completed_workout" ||
      day.streak.state === "incomplete_broken" ||
      day.streak.state === "frozen" ||
      day.streak.state === "upcoming_workout" ||
      day.streak.state === "incomplete_in_danger",
  ).length;
  const pastDaysWithWorkoutsCompleted = habitDaysQuery.data.habit_days.filter(
    (day) => day.streak.state === "completed_workout",
  ).length;

  if (
    habitDaysQuery.data.habit_days.at(-1)?.streak.state === "upcoming_workout"
  ) {
    pastDaysWithWorkoutsAssigned -= 1;
  }

  const workoutDaysCompletedPercent =
    (pastDaysWithWorkoutsCompleted / pastDaysWithWorkoutsAssigned) * 100;

  const weeksMap =
    habitDaysQuery.data?.habit_days.reduce(
      (acc, habitDay) => {
        const week = startOfWeek(
          toZonedTime(habitDay.date, "Etc/UTC"),
        ).toISOString();
        if (!acc[week]) {
          acc[week] = [];
        }

        acc[week].push(habitDay);

        return acc;
      },
      {} as Record<string, HabitDay[]>,
    ) ?? {};

  const weeks = Object.keys(weeksMap ?? {});

  return (
    <Box sx={sx}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <WhatshotRoundedIcon fontSize="inherit" />
        <Typography sx={{ ml: 1, fontWeight: "bold" }}>Consistency</Typography>
        {!client.settings.consistency_mode_disabled &&
          client.last_login_date && (
            <ClientStreak client={client} sx={{ ml: 2 }} />
          )}
      </Box>
      {!client.last_login_date ? (
        <Typography>Has not opened the app</Typography>
      ) : (
        <>
          <Tooltip
            title="The number of days a client did AT LEAST one trainwell workout out of the total days they had any assigned workouts over the last ~30 days"
            disableInteractive
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 24,
                lineHeight: 1,
                mb: 1,
              }}
            >
              {pastDaysWithWorkoutsCompleted}/{pastDaysWithWorkoutsAssigned}
              <Typography
                component={"span"}
                variant="overline"
                sx={{ display: "inline" }}
              >
                {" workouts, "}
                {Math.round((workoutDaysCompletedPercent ?? 0) * 10) / 10}%
              </Typography>
            </Typography>
          </Tooltip>
          <Stack direction={"column"} spacing={0.5} sx={{ mb: 1 }}>
            {weeks.map((week) => {
              const habitDays = weeksMap[week];

              return (
                <Stack
                  key={week}
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"flex-end"}
                >
                  {[0, 1, 2, 3, 4, 5, 6].map((day) => {
                    const habitDay = habitDays?.find(
                      (habitDay) =>
                        toZonedTime(
                          new Date(habitDay.date),
                          "Etc/UTC",
                        ).getDay() === day,
                    );

                    const isThisToday = habitDay
                      ? isToday(toZonedTime(new Date(habitDay.date), "Etc/UTC"))
                      : false;

                    const streakIcon = habitDay ? (
                      StreakIcon(habitDay.streak.state)
                    ) : (
                      <Box
                        sx={{
                          width: 18,
                          height: 18,
                        }}
                      />
                    );

                    return (
                      <Tooltip
                        key={day}
                        disableInteractive
                        arrow={false}
                        title={
                          !habitDay ? (
                            ""
                          ) : (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 1,
                                }}
                              >
                                {streakIcon}
                                <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                                  {isThisToday
                                    ? "Today :)"
                                    : format(
                                        toZonedTime(habitDay.date, "Etc/UTC"),
                                        "iiii, MMM do",
                                      )}
                                </Typography>
                              </Box>
                              <Stack spacing={0.5}>
                                {habitDay.habit_tasks.map((habitTask) => (
                                  <Box
                                    key={habitTask.id}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {habitTask.date_completed ? (
                                      <CheckBoxRoundedIcon fontSize="small" />
                                    ) : (
                                      <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                                    )}
                                    <Typography sx={{ ml: 1 }}>
                                      {habitTask.name}
                                    </Typography>
                                  </Box>
                                ))}
                              </Stack>
                            </>
                          )
                        }
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: (theme) =>
                                theme.palette.background.paper,
                              border: 1,
                              borderColor: "divider",
                              borderRadius: 1,
                              color: (theme) => theme.palette.text.primary,
                              boxShadow: 5,
                              p: 1,
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {isThisToday && <TodayDot />}
                          {streakIcon}
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        </>
      )}
    </Box>
  );
}

function TodayDot() {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: (theme) => theme.palette.primary.main,
        mb: 0.25,
      }}
    />
  );
}

function StreakIcon(streakState: StreakState) {
  switch (streakState) {
    case "completed_workout":
      return <CompletedWorkoutIcon />;
    case "incomplete_broken":
      return <StreakBrokenIcon />;
    case "completed_rest":
      return <CompletedRestDayIcon />;
    case "upcoming_rest":
      return <UpcomingRestDayIcon />;
    case "incomplete_in_danger":
      return <StreakInDangerIcon />;
    case "upcoming_workout":
      return <UpcomingWorkoutIcon />;
    case "frozen":
      return <StreakFrozenIcon />;
    default:
      return (
        <Box
          sx={{
            width: 18,
            height: 18,
          }}
        />
      );
  }
}
