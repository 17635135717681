import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { CoachVacation, Trainer } from "@trainwell/types";
import set from "lodash-es/set";
import { api } from "src/lib/trainwellApi";

export const fetchTrainerEdit = createAsyncThunk(
  "trainerEdit/fetchTrainerEdit",
  async (trainerID: string) => {
    const coach = await api.trainers.getOne(trainerID);
    return coach;
  },
);

export const fetchVacations = createAsyncThunk(
  "trainerEdit/fetchVacations",
  async (trainerId: string) => {
    const vacations = await api.vacations.getMany({ trainerId: trainerId });
    return vacations;
  },
);

export const addVacation = createAsyncThunk(
  "trainerEdit/addVacation",
  async (data: {
    trainerId: string;
    startDate: number;
    endDate: number;
    type: CoachVacation["type"];
    clientMessage?: string;
    localDateToSend?: number;
    requiresInterimCoaches: boolean;
  }) => {
    const response = await api.vacations.create(data);
    return response;
  },
);

// Define a type for the slice state
interface TriainerEditState {
  trainer: Trainer | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  vacations: CoachVacation[];
  vacationsStatus: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: TriainerEditState = {
  trainer: undefined,
  status: "idle",
  error: undefined,
  vacations: [],
  vacationsStatus: "idle",
};

export const trainerEditSlice = createSlice({
  name: "trainerEdit",
  initialState,
  reducers: {
    resetTrainerEdit: () => initialState,
    updateTrainerEdit: (
      state,
      action: PayloadAction<Partial<Trainer> & Pick<Trainer, "trainer_id">>,
    ) => {
      const update = action.payload;

      if (update.trainer_id === state.trainer?.trainer_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.trainer, key, value);
        }
      }
    },
    removeVacationFromCoach: (state, action: PayloadAction<string>) => {
      const vacationId = action.payload;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === vacationId,
      );

      if (index !== -1) {
        state.vacations.splice(index, 1);
      }
    },
    setVacationForCoach: (state, action: PayloadAction<CoachVacation>) => {
      const newVacation = action.payload;

      const index = state.vacations.findIndex(
        (vacation) => vacation.id === newVacation.id,
      );

      if (index !== -1) {
        state.vacations[index] = newVacation;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainerEdit.pending, (state) => {
      state.status = "loading";

      state.trainer = undefined;
      state.vacations = [];
      state.vacationsStatus = "idle";
    });
    builder.addCase(fetchTrainerEdit.fulfilled, (state, action) => {
      console.log("Redux: Got coach to edit");
      state.status = "succeeded";

      const coach = action.payload;

      state.trainer = coach;
    });
    builder.addCase(fetchTrainerEdit.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchVacations.pending, (state) => {
      state.vacationsStatus = "loading";
    });
    builder.addCase(fetchVacations.fulfilled, (state, action) => {
      console.log("Redux: Got coach vacations");
      state.vacationsStatus = "succeeded";

      const { vacations } = action.payload;

      vacations.sort((a, b) =>
        a.date_start < b.date_start ? 1 : b.date_start < a.date_start ? -1 : 0,
      );

      state.vacations = vacations;
    });
    builder.addCase(fetchVacations.rejected, (state) => {
      state.vacationsStatus = "failed";
    });
    builder.addCase(addVacation.fulfilled, (state, action) => {
      const vacation = action.payload;

      state.vacations.push(vacation);

      state.vacations.sort((a, b) =>
        a.date_start < b.date_start ? 1 : b.date_start < a.date_start ? -1 : 0,
      );
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetTrainerEdit,
  updateTrainerEdit,
  removeVacationFromCoach,
  setVacationForCoach,
} = trainerEditSlice.actions;

export default trainerEditSlice.reducer;
