import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SportsScoreRoundedIcon from "@mui/icons-material/SportsScoreRounded";
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { type MilestoneText } from "@trainwell/features";
import { differenceInSeconds, format, isFuture } from "date-fns";
import { useState } from "react";
import { MilestoneMoreMenu } from "./MilestoneMoreMenu";

type Props = {
  sx?: SxProps<Theme>;
  milestone: MilestoneText;
};

export function MilestoneCellText({ sx = [], milestone }: Props) {
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography>{milestone.content.text}</Typography>
        {milestone.date_to_achieve && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SportsScoreRoundedIcon fontSize="small" />
            <Box sx={{ flex: 1, ml: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {format(milestone.date_to_achieve, "MMM do, yyyy")}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  isFuture(milestone.date_to_achieve)
                    ? (differenceInSeconds(new Date(), milestone.date_created) /
                        differenceInSeconds(
                          milestone.date_to_achieve,
                          milestone.date_created,
                        )) *
                      100
                    : 100
                }
              />
            </Box>
          </Box>
        )}
        {milestone.date_achieved && (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            🎉 {format(milestone.date_achieved, "MMM do, yyyy")}
          </Typography>
        )}
      </Box>
      <Stack sx={{ ml: 1 }} direction={"row"} spacing={0} alignItems={"center"}>
        {milestone.notes && (
          <Tooltip title={milestone.notes} disableInteractive>
            <IconButton size="small">
              <StickyNote2RoundedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {!milestone.date_achieved && (
          <IconButton
            size="small"
            onClick={(event) => {
              setMoreAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      <MilestoneMoreMenu
        anchorEl={moreAnchorEl}
        milestone={milestone}
        onClose={() => {
          setMoreAnchorEl(null);
        }}
      />
    </Box>
  );
}
