import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ControlBar from "src/components/misc/ControlBar";
import LoadingPage from "src/components/miscPages/LoadingPage";
import NotFoundComponent from "src/components/miscPages/NotFoundComponent";
import SimpleVacationCell from "src/features/admin/company/SimpleVacationCell";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { fetchAllVacations } from "src/slices/vacationsSlice";

export default function CoachVacationsPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const vacations = useAppSelector((state) => state.vacations.vacations);
  const vacationsStatus = useAppSelector((state) => state.vacations.status);
  const [onlyUpcoming, setOnlyUpcoming] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyUpcoming(event.target.checked);
  };

  useEffect(() => {
    dispatch(
      fetchAllVacations({
        onlyFetchUpcoming: onlyUpcoming,
        trainerId: trainer?.trainer_id,
      }),
    );
  }, [dispatch, onlyUpcoming, trainer?.trainer_id]);

  return (
    <>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Box sx={{ px: 3, pt: 3, borderBottom: 1, borderColor: "divider" }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Vacations
        </Typography>
        <FormGroup sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Switch checked={onlyUpcoming} onChange={handleChange} />}
            label="Show only upcoming"
          />
        </FormGroup>
      </Box>
      {vacationsStatus === "loading" ? (
        <LoadingPage message="Getting vacations" />
      ) : vacations.length === 0 ? (
        <NotFoundComponent message="No vacations found" />
      ) : (
        <Stack
          direction="column"
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          {vacations.map((vacation) => (
            <SimpleVacationCell
              key={vacation.id}
              vacationId={vacation.id}
              disableEditing
            />
          ))}
        </Stack>
      )}
    </>
  );
}
