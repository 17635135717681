import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { useAudits, useCreateAudit } from "@trainwell/features";
import { useState } from "react";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import AuditCell from "./AuditCell";

type Props = {
  userId: string;
  freeFloating?: boolean;
};

export default function AuditPanel({ userId, freeFloating = false }: Props) {
  const realTrainer = useAppSelector((state) => state.trainer.trainer);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [isExpanded, setIsExpanded] = useState(false);
  const createAudit = useCreateAudit();
  const { data: audits, isPending: isPendingAudits } = useAudits({
    filter: {
      user_id: userId,
    },
    queryConfig: {
      enabled: isExpanded,
    },
  });

  const displayedAudits = realTrainer?.is_admin
    ? audits
    : audits?.filter(
        (audit) => audit.auditor_trainer_id === trainer?.trainer_id,
      );

  return (
    <Accordion
      disableGutters
      elevation={freeFloating ? undefined : 0}
      square={!freeFloating}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      expanded={isExpanded}
      slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
      variant={freeFloating ? "outlined" : "elevation"}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Typography variant="h6">🔍 Audits</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {isPendingAudits ? (
            <LoadingComponent />
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Button
                  fullWidth
                  startIcon={<AddRoundedIcon />}
                  onClick={() => {
                    createAudit.mutate({
                      data: {
                        subject_user_id: userId,
                        auditor_trainer_id: realTrainer!.trainer_id,
                      },
                    });
                  }}
                >
                  Start new audit
                </Button>
              </Box>
              <Stack spacing={2} direction="column">
                {displayedAudits?.map((audit) => {
                  return <AuditCell audit={audit} key={audit.id} />;
                })}
              </Stack>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
