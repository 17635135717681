import { Box } from "@mui/material";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ActionItemHelper from "src/components/misc/ActionItemHelper";
import AppHooks from "src/components/misc/AppHooks";
import AuthHelper from "src/components/misc/AuthHelper";
import { GlobalTemplateDialog } from "src/components/misc/GlobalTemplateDialog";
import PwaHelper from "src/components/misc/PwaHelper";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { Chat } from "src/features/chat/Chat";
import { AppProviders } from "./AppProviders";

export function Root() {
  return (
    <Suspense fallback={<LoadingComponent message="Loading component" />}>
      <AppProviders>
        <Box sx={{ display: "flex" }}>
          <Chat />
          <Box sx={{ flex: 1, maxWidth: "100%" }}>
            <Outlet />
          </Box>
        </Box>
        <GlobalTemplateDialog />
        <AuthHelper />
        <PwaHelper />
        <AppHooks />
        <ActionItemHelper />
      </AppProviders>
    </Suspense>
  );
}
