import type { Theme } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import CircularStdBold from "src/assets/fonts/circular-std-bold.woff2";
import CircularStdBook from "src/assets/fonts/circular-std-book.woff2";
import CircularStdLight from "src/assets/fonts/circular-std-light.woff2";
import { LinkBehavior } from "../components/misc/LinkBehavior";
import { chartsCustomizations } from "./charts";
import type { ThemeMode } from "./themeModes";
import { darkTheme, lightSoftTheme, lightTheme } from "./themeModes";

// Create a theme instance.
export default function getTheme(themeMode: ThemeMode): Theme {
  const partialColorPalette =
    themeMode === "dark"
      ? darkTheme
      : themeMode == "light_soft"
        ? lightSoftTheme
        : lightTheme;

  const themeWithColorPallette = createTheme({ palette: partialColorPalette });

  return responsiveFontSizes(
    createTheme({
      palette: partialColorPalette,
      typography: {
        fontFamily: "'CircularStd', sans-serif",
        fontSize: 14,
        h1: {
          fontSize: 22,
          fontWeight: "bold",
        },
        h2: {
          fontSize: 18,
          fontWeight: "bold",
        },
        h3: {
          fontSize: 16,
          fontWeight: "bold",
        },
        h4: {
          fontSize: 15,
          fontWeight: "bold",
        },
        h5: {
          fontSize: 14,
          fontWeight: "bold",
        },
        h6: {
          fontSize: 14,
          fontWeight: "bold",
          lineHeight: 1.5,
        },
        subtitle1: {
          fontSize: 14,
        },
        subtitle2: {
          fontWeight: "bold",
        },
        body1: {
          fontSize: 14,
        },
        body2: {
          fontSize: 12,
        },
        button: {
          textTransform: "none",
          fontSize: 14,
          fontWeight: "bold",
        },
        overline: {
          fontSize: 12,
          color: themeWithColorPallette.palette.text?.secondary,
          textTransform: "none",
          lineHeight: 1.43,
          display: "block",
        },
      },
      shape: {
        borderRadius: 12,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
          @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local('CircularStd'), local('CircularStd-Light'), url(${CircularStdLight}) format('woff2');
          }

          @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('CircularStd'), local('CircularStd-Book'), url(${CircularStdBook}) format('woff2');
          }
            
            @font-face {
              font-family: 'CircularStd';
              font-style: normal;
              font-display: swap;
              font-weight: 700;
              src: local('CircularStd'), local('CircularStd-Bold'), url(${CircularStdBold}) format('woff2');
            }
          `,
        },
        MuiTab: {
          styleOverrides: {
            root: {
              // Should be same as button
              fontWeight: "bold",
              textTransform: "none",
              fontSize: 14,
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: 14,
              px: "12px",
              py: "6px",
              lineHeight: "normal",
              borderRadius: "4px",
            },
          },
          defaultProps: {
            arrow: true,
          },
        },
        MuiButton: {
          defaultProps: {
            disableElevation: true,
            variant: "contained",
          },
          styleOverrides: {
            root: {
              borderRadius: "100vmax",
            },
          },
        },
        MuiIconButton: {
          defaultProps: {
            color: "primary",
          },
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          },
        },
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
        MuiListItemButton: {
          defaultProps: {
            LinkComponent: LinkBehavior,
            sx: {
              borderRadius: 1,
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: 48,
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
        MuiToolbar: {
          defaultProps: {
            variant: "dense",
            sx: { minHeight: "38px" },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: 28,
            },
          },
        },
        MuiDialogTitle: {
          defaultProps: {
            variant: "h1",
          },
          styleOverrides: {
            root: {
              paddingTop: 24,
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: 24,
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              borderRadius: 4,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 4,
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              borderRadius: 4,
            },
          },
        },
        ...chartsCustomizations,
      },
    }),
  );
}

declare module "@mui/material/styles" {
  interface Palette {
    successSurface: Palette["primary"];
    warningSurface: Palette["primary"];
    errorSurface: Palette["primary"];
    purple: Palette["primary"];
    purpleSurface: Palette["primary"];
    blue: Palette["primary"];
    blueSurface: Palette["primary"];
    backgroundSecondary: Palette["primary"];
    backgroundTertiary: Palette["primary"];
    backgroundDark: Palette["primary"];
    link: Palette["primary"];
    pink: Palette["primary"];
  }

  interface PaletteOptions {
    successSurface: PaletteOptions["primary"];
    warningSurface: PaletteOptions["primary"];
    errorSurface: PaletteOptions["primary"];
    purple: PaletteOptions["primary"];
    purpleSurface: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    blueSurface: PaletteOptions["primary"];
    backgroundSecondary: PaletteOptions["primary"];
    backgroundTertiary: PaletteOptions["primary"];
    backgroundDark: PaletteOptions["primary"];
    link: PaletteOptions["primary"];
    pink: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    successSurface: true;
    warningSurface: true;
    errorSurface: true;
    purpleSurface: true;
    blueSurface: true;
  }
}
