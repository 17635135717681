import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import {
  Autocomplete,
  Box,
  ButtonBase,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useWorkoutCompletion } from "@trainwell/features";
import type { Client, Trainer } from "@trainwell/types";
import { isFuture } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import SearchField from "src/components/misc/SearchField";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getUserIdsMatchingFilter } from "src/lib/chat";
import { getSortedClientList } from "src/slices/chatSlice";
import {
  selectAllClients,
  selectClientLoad,
  toggleClientTag,
} from "src/slices/clientsSlice";
import {
  selectIsGhosting,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import ClientCell from "./ClientCell";
import ClientFilterButton from "./ClientFilterButton";
import ClientGridCell from "./ClientGridCell";

const icon = <CheckBoxOutlineBlankRoundedIcon fontSize="small" />;
const checkedIcon = <CheckBoxRoundedIcon fontSize="small" />;

export default function ClientSelector() {
  const dispatch = useAppDispatch();
  const isGhosting = useAppSelector(selectIsGhosting);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const possibleTags = useAppSelector((state) => state.clients.possibleTags);
  const [search, setSearch] = useState("");
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const actionItems = useAppSelector((state) => state.actionItems.actionItems);
  const clientLoad = useAppSelector(selectClientLoad);
  // eslint-disable-next-line prefer-const
  let [displayMode, setDisplayMode] = useState(
    trainer?.settings.client_display_mode ?? "list",
  );
  const [clientFilters, setClientFilters] = useState<string[]>(
    "client_filters" in sessionStorage
      ? JSON.parse(sessionStorage.getItem("client_filters")!)
      : ["visible"],
  );
  const [filterMode, setFilterMode] = useState<"and" | "or">("and");
  const { data: workoutCompletion } = useWorkoutCompletion({
    trainerId: trainer?.trainer_id ?? "",
  });
  const [tags, setTags] = useState(
    "client_tags" in sessionStorage
      ? JSON.parse(sessionStorage.getItem("client_tags")!)
      : [],
  );
  const clientInfo = useAppSelector((state) => state.clients.clientInfo);
  const clientStatus = useAppSelector((state) => state.clients.status);
  const clients = useAppSelector(selectAllClients);
  const visibleClients = clients.filter((c) => !c.account.dashboard.is_hidden);
  const [completedCount, setCompletedCount] = useState(0);
  const [tenureType, setTenureType] = useState<"with_coach" | "with_trainwell">(
    "with_coach",
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deletingTag, setDeletingTag] = useState(false);
  const open = Boolean(anchorEl);

  const sort = useMemo(
    () =>
      trainer?.settings.client_sort ??
      ({
        field: "name",
        direction: "asc",
      } as NonNullable<Trainer["settings"]["client_sort"]>),
    [trainer?.settings],
  );

  const currentClientIndex =
    "current_client_index" in sessionStorage
      ? JSON.parse(sessionStorage.getItem("current_client_index")!)
      : 0;

  if (dashMode === "default") {
    displayMode = "list";
  }

  useEffect(() => {
    localStorage.setItem("client_sort_2", JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    let count = 0;
    Object.entries(clientInfo).forEach(([key, value]) => {
      const client = clients.find((client) => client.user_id === key);

      if (client && !client.account.dashboard.is_hidden) {
        if (value.weeks >= 2 && client.account.dashboard.date_onboarded) {
          count++;
        }
      }
    });

    const unonboardedCount = clients.filter(
      (client) =>
        !client.account.dashboard.is_hidden &&
        !client.account.dashboard.date_onboarded,
    ).length;

    setCompletedCount(count + unonboardedCount);
  }, [clientInfo, clients]);

  const sortedClients = useMemo(() => {
    let clientList = JSON.parse(JSON.stringify(clients)) as Client[];

    if (search !== "") {
      clientList = clientList.filter((client) =>
        client.full_name.toLowerCase().includes(search!.toLowerCase()),
      );
    }

    clientList = getSortedClientList(clientList, sort, clientInfo);

    if (tags.length !== 0) {
      clientList = clientList.filter((client) => {
        for (const tag of tags) {
          if (
            !client.tags ||
            !client.tags.map((t) => t.toLowerCase()).includes(tag.toLowerCase())
          ) {
            return false;
          }
        }

        return true;
      });
    }

    if (clientFilters.length > 0) {
      const filteredUserIdSets: string[][] = [];

      for (const filter of clientFilters) {
        const filteredUserIds = getUserIdsMatchingFilter(
          filter,
          clients,
          clientInfo,
          actionItems,
        );

        filteredUserIdSets.push(filteredUserIds);
      }

      clientList = clientList.filter((client) =>
        filterMode === "and"
          ? filteredUserIdSets.every((filteredUserIds) =>
              filteredUserIds.includes(client.user_id),
            )
          : filteredUserIdSets.some((filteredUserIds) =>
              filteredUserIds.includes(client.user_id),
            ),
      );
    }

    return clientList;
  }, [
    clientInfo,
    clients,
    search,
    sort.direction,
    sort.field,
    tags,
    clientFilters,
    actionItems,
    filterMode,
  ]);

  function toggleSort(field: (typeof sort)["field"]) {
    if (sort.field === field) {
      dispatch(
        updateTrainer({
          trainer_id: trainer!.trainer_id,
          settings: {
            ...trainer!.settings,
            client_sort: {
              field: sort.field,
              direction: sort.direction === "asc" ? "desc" : "asc",
            },
          },
        }),
      );
    } else {
      dispatch(
        updateTrainer({
          trainer_id: trainer!.trainer_id,
          settings: {
            ...trainer!.settings,
            client_sort: {
              field: field,
              direction: "desc",
            },
          },
        }),
      );
    }
  }

  const referralsInTrialCount = clients.filter(
    (c) =>
      c.account.dashboard.date_onboarded &&
      c.account.plan.date_trial_end &&
      isFuture(new Date(c.account.plan.date_trial_end)) &&
      c.referral_ids?.referral_id,
  ).length;

  let clientsInReferralPairCount = 0;
  let clientsWithReferralsInTrialCount = 0;

  for (const client of clients) {
    if (
      clientInfo[client.user_id]?.referred_clients.length > 0 ||
      client.referral_ids?.referral_id
    ) {
      clientsInReferralPairCount++;
    }

    if (clientInfo[client.user_id]?.referred_clients_in_trial.length > 0) {
      clientsWithReferralsInTrialCount++;
    }
  }

  return (
    <Box
      sx={{
        mt: "38px",
        height: "calc(100vh - 38px)",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          mx: 2,
          my: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Clients</Typography>
        <Box sx={{ display: "flex", flex: 1, alignItems: "center", mx: 2 }}>
          <SearchField
            value={search}
            onChange={(value) => {
              setSearch(value as string);
            }}
            onClear={() => {
              setSearch("");
            }}
          />
          <Autocomplete
            multiple
            options={possibleTags}
            disableCloseOnSelect
            size="small"
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingTop: 0,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8, padding: 4 }}
                    checked={selected}
                  />
                  {option}
                </Box>
                <IconButton
                  size="small"
                  color="inherit"
                  disabled={deletingTag}
                  onClick={(event) => {
                    event.stopPropagation();

                    setDeletingTag(true);

                    const promises: Promise<any>[] = [];

                    for (const client of clients) {
                      if (client.tags?.includes(option)) {
                        promises.push(
                          dispatch(
                            toggleClientTag({
                              tag: option,
                              userId: client.user_id,
                            }),
                          ).unwrap(),
                        );
                      }
                    }

                    Promise.allSettled(promises).then(() => {
                      setDeletingTag(false);
                    });
                  }}
                >
                  <DeleteRoundedIcon fontSize="inherit" color="error" />
                </IconButton>
              </li>
            )}
            fullWidth
            sx={{ mx: 2, maxWidth: "350px" }}
            renderInput={(params) => <TextField {...params} label={"Tags"} />}
            value={tags}
            onChange={(event, newValue) => {
              setTags(newValue ?? []);
              sessionStorage.setItem(
                "client_tags",
                JSON.stringify(newValue ?? []),
              );
            }}
          />
          <ClientFilterButton
            filters={clientFilters}
            onChange={(newFilters) => {
              setClientFilters(newFilters);

              sessionStorage.setItem(
                "client_filters",
                JSON.stringify(newFilters ?? []),
              );
            }}
            mode={filterMode}
            onModeChange={(newMode) => {
              setFilterMode(newMode);
            }}
          />
        </Box>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Tooltip
            disableInteractive
            title="Referrals pre-payment - Total clients you are training that are in a trial and from a referral"
          >
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>❤️</Typography>
              <Typography>{referralsInTrialCount}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip
            disableInteractive
            title="Referrals sent pre-payment - Total clients you are training that sent a referral where that referral is currently in a trial"
          >
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>💌</Typography>
              <Typography>{clientsWithReferralsInTrialCount}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip
            disableInteractive
            title="Referral clients - Total clients you are training that are part of a referral pair (either sent or received a referral from another active user)"
          >
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>💙</Typography>
              <Typography>{clientsInReferralPairCount}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip disableInteractive title="Client capacity">
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>👤</Typography>
              <Typography>{trainer?.client_capacity}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip disableInteractive title="Client load">
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>👩</Typography>
              <Typography>{clientLoad}</Typography>
            </Stack>
          </Tooltip>
          <Tooltip disableInteractive title="Workout completion">
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>🏋️</Typography>
              <Typography>
                {workoutCompletion ? Math.round(workoutCompletion) : "--"}%
              </Typography>
            </Stack>
          </Tooltip>
          <Tooltip disableInteractive title="Clients complete">
            <Stack direction={"column"} alignItems={"center"}>
              <Typography>✅</Typography>
              <Typography>
                {completedCount} / {visibleClients.length} (
                {Math.round((completedCount / visibleClients.length) * 100)}
                %)
              </Typography>
            </Stack>
          </Tooltip>
          {dashMode === "programming" && (
            <ToggleButtonGroup
              value={displayMode}
              exclusive
              size="small"
              onChange={(event, newValue) => {
                if (newValue === null) {
                  return;
                }

                if (!isGhosting) {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer!.trainer_id,
                      settings: {
                        ...trainer!.settings,
                        client_display_mode: newValue,
                      },
                    }),
                  );
                }

                setDisplayMode(newValue);
              }}
            >
              <ToggleButton value="list">
                <FormatListBulletedRoundedIcon />
              </ToggleButton>
              <ToggleButton value="grid">
                <AppsRoundedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Stack>
      </Box>
      <Card
        variant="outlined"
        sx={{
          mx: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "200px",
              }}
            >
              <ColumnHeader
                label="Name"
                sortDirection={sort.field !== "name" ? null : sort.direction}
                onChange={() => {
                  toggleSort("name");
                }}
              />
            </Grid>
            <Grid item xs>
              <ColumnHeader
                label="Next workout"
                sortDirection={
                  sort.field !== "next_workout" ? null : sort.direction
                }
                onChange={() => {
                  toggleSort("next_workout");
                }}
              />
            </Grid>
            <Grid item xs>
              <Tooltip title="Streak of missed days that had something assigned. Doing a trainwell workout resets this to 0.">
                <span>
                  <ColumnHeader
                    label="Missed streak"
                    sortDirection={
                      sort.field !== "missed_streak" ? null : sort.direction
                    }
                    onChange={() => {
                      toggleSort("missed_streak");
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Tooltip title="Percent of assigned workouts that have been completed in the last 7 days">
                <span>
                  <ColumnHeader
                    label="Workout comp."
                    sortDirection={
                      sort.field !== "workout_completion"
                        ? null
                        : sort.direction
                    }
                    onChange={() => {
                      toggleSort("workout_completion");
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <ColumnHeader
                label="Last message"
                sortDirection={
                  sort.field !== "last_message" ? null : sort.direction
                }
                onChange={() => {
                  toggleSort("last_message");
                }}
              />
            </Grid>
            <Grid item xs>
              <ColumnHeader
                label="Last workout"
                sortDirection={
                  sort.field !== "last_workout" ? null : sort.direction
                }
                onChange={() => {
                  toggleSort("last_workout");
                }}
              />
            </Grid>
            <Grid item xs>
              <ButtonBase
                onClick={() => {
                  toggleSort(
                    tenureType === "with_coach"
                      ? "tenure_with_coach"
                      : "tenure_with_trainwell",
                  );
                }}
                onContextMenu={(event) => {
                  event.preventDefault();

                  setAnchorEl(event.currentTarget);
                }}
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                  pr: 0.5,
                  backgroundColor: (theme) =>
                    sort.field !== "tenure_with_coach" &&
                    sort.field !== "tenure_with_trainwell"
                      ? undefined
                      : alpha(theme.palette.primary.main, 0.1),
                }}
              >
                <Typography
                  sx={{
                    color: (theme) =>
                      sort.field !== "tenure_with_coach" &&
                      sort.field !== "tenure_with_trainwell"
                        ? undefined
                        : theme.palette.primary.main,
                    fontWeight:
                      sort.field !== "tenure_with_coach" &&
                      sort.field !== "tenure_with_trainwell"
                        ? undefined
                        : "bold",
                    fontSize: 14,
                  }}
                >
                  {tenureType === "with_coach"
                    ? "Tenure (you)"
                    : "Tenure (trainwell)"}
                </Typography>
                {sort.field !== "tenure_with_coach" &&
                sort.field !==
                  "tenure_with_trainwell" ? null : sort.direction === "desc" ? (
                  <ExpandMoreRoundedIcon fontSize="small" />
                ) : (
                  <ExpandLessRoundedIcon fontSize="small" />
                )}
              </ButtonBase>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuItem
                  selected={tenureType === "with_coach"}
                  onClick={() => {
                    setTenureType("with_coach");
                    toggleSort("tenure_with_coach");
                    setAnchorEl(null);
                  }}
                >
                  Tenure with you
                </MenuItem>
                <MenuItem
                  selected={tenureType === "with_trainwell"}
                  onClick={() => {
                    setTenureType("with_trainwell");
                    toggleSort("tenure_with_trainwell");
                    setAnchorEl(null);
                  }}
                >
                  Tenure with trainwell
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs>
              <ColumnHeader
                label="Weeks"
                sortDirection={sort.field !== "weeks" ? null : sort.direction}
                onChange={() => {
                  toggleSort("weeks");
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {clientStatus !== "succeeded" ? (
          <LoadingComponent message="Fetching clients" />
        ) : (
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            {displayMode === "grid" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  gridColumnGap: 8,
                  margin: 2,
                }}
              >
                {sortedClients.map((client) => (
                  <ClientGridCell
                    key={client.user_id}
                    userId={client.user_id}
                  />
                ))}
              </Box>
            ) : (
              <Virtuoso
                data={sortedClients}
                initialTopMostItemIndex={currentClientIndex}
                style={{ height: "100%" }}
                overscan={500}
                itemContent={(index, client) => (
                  <ClientCell
                    index={index}
                    userId={client.user_id}
                    key={client.user_id}
                    tenureType={tenureType}
                  />
                )}
              />
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
}

type ColumnHeaderProps = {
  onChange: () => void;
  label: string;
  sortDirection: "asc" | "desc" | null;
};

function ColumnHeader({ onChange, label, sortDirection }: ColumnHeaderProps) {
  return (
    <ButtonBase
      onClick={() => {
        onChange();
      }}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        py: 1,
        px: 0.5,
        backgroundColor: (theme) =>
          sortDirection !== null
            ? alpha(theme.palette.primary.main, 0.1)
            : undefined,
      }}
    >
      <Typography
        sx={{
          color: (theme) =>
            sortDirection !== null ? theme.palette.primary.main : undefined,
          fontWeight: sortDirection !== null ? "bold" : undefined,
          fontSize: 14,
        }}
      >
        {label}
      </Typography>
      {sortDirection === null ? null : sortDirection === "desc" ? (
        <ExpandMoreRoundedIcon fontSize="small" />
      ) : (
        <ExpandLessRoundedIcon fontSize="small" />
      )}
    </ButtonBase>
  );
}
