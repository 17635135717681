import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import MissedVideoCallRoundedIcon from "@mui/icons-material/MissedVideoCallRounded";
import PhoneDisabledRoundedIcon from "@mui/icons-material/PhoneDisabledRounded";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { isBlank, listClientMeetings } from "@trainwell/features";
import { subDays } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControlBar from "src/components/misc/ControlBar";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { api } from "src/lib/trainwellApi";
import { sendTextMessage } from "src/slices/chatSlice";
import {
  fetchClient,
  setOnboarded,
  updateClient,
} from "src/slices/clientSlice";
import { updateClientInListLocal } from "src/slices/clientsSlice";
import { exitGhostMode, selectPrimaryTrainer } from "src/slices/trainerSlice";
import AuditPanel from "./client-column/AuditPanel";
import { ClientEquipment } from "./client-column/ClientEquipment";
import TagsPanel from "./client-column/TagsPanel";
import ClientSchedule from "./ClientSchedule";
import ClientCard from "./name-tag/ClientCard";
import OnboardingNotesPanel from "./OnboardingNotesPanel";

type OnboardingButtonType = "no_show" | "reschedule" | "save";

export default function OnboardingPage() {
  const navigate = useNavigate();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const realCoach = useAppSelector((state) => state.trainer.trainer);
  const [isSavingClient, setIsSavingClient] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [message, setMessage] = useState(
    trainer?.message_templates.client_finished_onboarding_call,
  );
  const [onboardingButtonType, setOnboardingButtonType] = useState<
    OnboardingButtonType | undefined
  >();
  const [callData, setCallData] = useState<
    | {
        didCall: boolean | null;
        reason: string | null;
      }
    | undefined
  >();
  const [didCallDialogOpen, setDidCallDialogOpen] = useState(false);
  const [noCallReasonDialogOpen, setNoCallReasonDialogOpen] = useState(false);
  const [missedMessageDialogOpen, setMissedMessageDialogOpen] = useState(false);
  const [missedMessage, setMissedMessage] = useState(
    trainer?.message_templates.client_missed_onboarding_call,
  );
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const [isLoading, setIsLoading] = useState(false);
  const [setCallingExpectations, setSetCallingExpectations] = useState<
    boolean | null
  >(null);
  const isInCallTest = client?.tests?.includes(
    "call_missed_workout_clients_treatment",
  );

  async function recordOnboardingEvent(options: {
    clientAttended: boolean;
    callRescheduled: boolean;
    didCall?: boolean;
  }) {
    const { clientAttended, callRescheduled, didCall } = options;

    if (!client) {
      return;
    }

    let eventId: string | undefined;

    const now = new Date();
    const oneDayAgo = subDays(now, 1);

    const events = await listClientMeetings({
      userId: client.user_id,
      dateAfter: oneDayAgo.toISOString(),
      dateBefore: now.toISOString(),
    });

    if (events.length) {
      const mostRecentEvent = events.sort((a, b) => {
        return (b.date_start as string).localeCompare(a.date_start as string);
      })[0];

      eventId = mostRecentEvent.id;
    }

    return api.analytics.trackEvent({
      trainerId: trainer?.trainer_id,
      eventType: "onboarding_period_ended",
      platform: "coach_dash",
      sessionId: "",
      eventContent: {
        user_id: client.user_id,
        event_id: eventId,
        client_attended: clientAttended,
        call_rescheduled: callRescheduled,
        recording_url: null,
        called_client_phone: didCall,
      },
    });
  }

  async function handleOnboardingButtonClick(type: OnboardingButtonType) {
    setIsLoading(true);
    setOnboardingButtonType(type);

    if (callData === undefined && type === "no_show") {
      setDidCallDialogOpen(true);

      return;
    }

    if (type === "no_show") {
      if (callData?.didCall === false) {
        await api.clients.noShowNoCall(client?.user_id ?? "", {
          reason: callData?.reason ?? "",
        });
      }

      await recordOnboardingEvent({
        clientAttended: false,
        callRescheduled: false,
        didCall: callData?.didCall ?? undefined,
      });

      setMissedMessageDialogOpen(true);
    } else if (type === "reschedule") {
      await recordOnboardingEvent({
        clientAttended: false,
        callRescheduled: true,
        didCall: undefined,
      });

      navigate("/");
    } else if (type === "save") {
      await recordOnboardingEvent({
        clientAttended: true,
        callRescheduled: false,
        didCall: undefined,
      });

      setMessageDialogOpen(true);

      if (isInCallTest) {
        api.slack.sendMessage({
          channelId: "C07GA6J3T6X",
          message: `🎉 *<https://leadtrainer.trainwell.net/clients/${client?.user_id}|${client?.full_name}>* has been onboarded by ${trainer?.full_name}!`,
        });
      }
    }
  }

  async function completeOnboarding(message?: string) {
    setIsSavingClient(true);

    if (!client) {
      return;
    }

    await api.weekPlans.createBlank(client.user_id);

    await api.clients.setOnboarded(client.user_id).then(() => {
      setMessageDialogOpen(false);
    });

    if (message) {
      dispatch(
        sendTextMessage({
          userId: client.user_id,
          text: message,
        }),
      );
    }

    dispatch(setOnboarded());

    dispatch(
      updateClientInListLocal({
        user_id: client.user_id,
        // @ts-expect-error
        "account.dashboard.date_onboarded": new Date().toISOString(),
      }),
    );

    dispatch(fetchClient(client.user_id));

    if (setCallingExpectations === false) {
      await api.slack.sendMessage({
        channelId: "C07GA6J3T6X",
        message: `Trainer ${trainer?.full_name} did not set calling expectations for their client <https://leadtrainer.trainwell.net/clients/${client.user_id}|${client.full_name}>`,
      });
    }
  }

  // useEffect(() => {
  //   if (callData !== undefined) {
  //     setDidCallDialogOpen(false);
  //     handleOnboardingButtonClick(onboardingButtonType!);
  //   }
  // }, [callData]);

  if (!client) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  return (
    <>
      <ControlBar
        leftContent={
          trainerHasAccess(realCoach?.access_roles, "client_list") && (
            <Button
              variant="text"
              startIcon={<AdminPanelSettingsRoundedIcon />}
              href={`/admin/company/clients/${client.user_id}`}
              onClick={() => {
                dispatch(exitGhostMode());
              }}
              sx={{ ml: 1 }}
            >
              Admin dash
            </Button>
          )
        }
      />
      <Box
        sx={{
          mt: "38px",
          pt: 4,
          height: "calc(100vh - 38px)",
          overflowY: "auto",
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <ClientCard />
            </Grid>
            <Grid item xs={12}>
              <TagsPanel userId={client.user_id} freeFloating />
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  🏋️ Equipment
                </Typography>
                <ClientEquipment userId={client.user_id} />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                  📅 Workout schedule
                </Typography>
                <ClientSchedule variant="full" />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <OnboardingNotesPanel userId={client.user_id} />
            </Grid>
            <Grid item xs={12}>
              <AuditPanel freeFloating userId={client.user_id} />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              mb: 8,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mr: 2,
              }}
            >
              <LoadingButton
                loading={isLoading}
                variant="contained"
                sx={{ mb: 1 }}
                disabled={messageDialogOpen || missedMessageDialogOpen}
                onClick={async () => {
                  handleOnboardingButtonClick("no_show");
                }}
                color="error"
                startIcon={<MissedVideoCallRoundedIcon />}
              >
                Client didn&apos;t show up
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mr: 2,
              }}
            >
              <LoadingButton
                loading={isLoading}
                variant="contained"
                sx={{ mb: 1 }}
                disabled={messageDialogOpen || missedMessageDialogOpen}
                onClick={async () => {
                  handleOnboardingButtonClick("reschedule");
                }}
                color="warning"
                startIcon={<CalendarMonthRoundedIcon />}
              >
                Rescheduled
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ml: 2,
              }}
            >
              <LoadingButton
                loading={isLoading}
                onClick={async () => {
                  handleOnboardingButtonClick("save");
                }}
                disabled={messageDialogOpen || missedMessageDialogOpen}
                sx={{ mb: 1 }}
                variant="contained"
                color="success"
              >
                Save and onboard
              </LoadingButton>
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  textAlign: "center",
                }}
              >
                Starts your client&apos;s free trial
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Dialog open={messageDialogOpen} fullWidth maxWidth="sm">
        <DialogTitle>Finish onboarding 🎉</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {client.first_name} is excited to get started on their trainwell
            journey! Hype them up and let them know what to expect!
          </DialogContentText>
          <TextField
            multiline
            minRows={2}
            fullWidth
            label="Message"
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            sx={{ mb: 1 }}
          />
          {isInCallTest && (
            <>
              <Typography sx={{ mb: 1, mt: 1 }}>
                Did you set expectation around calling the client for missed
                workouts?
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Button
                  onClick={() => {
                    setSetCallingExpectations(true);
                  }}
                  startIcon={
                    setCallingExpectations === true && <CheckRoundedIcon />
                  }
                >
                  Yes
                </Button>
                <Button
                  color="error"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setSetCallingExpectations(false);
                  }}
                  startIcon={
                    setCallingExpectations === false && <CheckRoundedIcon />
                  }
                >
                  No
                </Button>
              </Box>
            </>
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={client.interested_in_sleep}
                  onChange={(event, newValue) => {
                    setIsSavingClient(true);

                    dispatch(
                      updateClient({
                        user_id: client.user_id,
                        interested_in_sleep: newValue,
                      }),
                    ).then(() => {
                      setIsSavingClient(false);
                    });
                  }}
                />
              }
              label="Interested in better sleep"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setMessageDialogOpen(false);
            }}
            disabled={isSavingClient}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            onClick={() => {
              completeOnboarding();
            }}
            disabled={
              isSavingClient ||
              (isInCallTest && setCallingExpectations === null)
            }
          >
            This client doesn&apos;t need a message
          </Button>
          <Button
            variant="contained"
            startIcon={<SendRoundedIcon />}
            onClick={() => {
              completeOnboarding(message);
            }}
            disabled={
              isBlank(message) ||
              isSavingClient ||
              (isInCallTest && setCallingExpectations === null)
            }
          >
            Send message
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={missedMessageDialogOpen} fullWidth maxWidth="sm">
        <DialogTitle>Reschedule the call 📅</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Ask {client.first_name} if they can do their onboarding call a
            different time.
          </DialogContentText>
          <TextField
            multiline
            minRows={2}
            fullWidth
            label="Message"
            value={missedMessage}
            onChange={(event) => {
              setMissedMessage(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setMissedMessageDialogOpen(false);
              setIsLoading(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={<SendRoundedIcon />}
            onClick={() => {
              if (!missedMessage) {
                return;
              }

              dispatch(
                sendTextMessage({
                  userId: client.user_id,
                  text: missedMessage,
                }),
              );

              api.clients.setMissedOnboardingCall(client.user_id);
              navigate("/");
            }}
            disabled={isBlank(missedMessage)}
          >
            Send message
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={didCallDialogOpen}>
        <DialogTitle>Did you try calling the client?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setCallData({
                didCall: true,
                reason: null,
              });

              setDidCallDialogOpen(false);
              handleOnboardingButtonClick(onboardingButtonType!);
            }}
            startIcon={<PhoneEnabledRoundedIcon />}
          >
            Yes, I tried to call them
          </Button>
          <Button
            onClick={() => {
              setCallData({
                didCall: false,
                reason: null,
              });

              setDidCallDialogOpen(false);

              if (onboardingButtonType === "no_show") {
                setNoCallReasonDialogOpen(true);
              } else {
                handleOnboardingButtonClick(onboardingButtonType!);
              }
            }}
            startIcon={<PhoneDisabledRoundedIcon />}
          >
            No, I did not try to call them
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={noCallReasonDialogOpen}>
        <DialogTitle>Why didn&apos;t you call the client?</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason"
            multiline
            fullWidth
            value={callData?.reason ?? ""}
            onChange={(event) => {
              setCallData({
                didCall: false,
                reason: event.target.value,
              });
            }}
            helperText="Required"
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNoCallReasonDialogOpen(false);
              handleOnboardingButtonClick(onboardingButtonType!);
            }}
            disabled={isBlank(callData?.reason)}
          >
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
