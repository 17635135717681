import { Box, ThemeProvider, useMediaQuery } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ChatList from "src/features/chat/ChatList";
import { ChatListHeader } from "src/features/chat/ChatListHeader";
import CheckInPanel from "src/features/chat/CheckInPanel";
import ClientChat from "src/features/chat/ClientChat";
import { ClientChatHeader } from "src/features/chat/ClientChatHeader";
import { ThreadsList } from "src/features/chat/threads/ThreadsList";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  closeChat,
  openChat,
  selectChatThemeMode,
  selectSelectedChat,
} from "src/slices/chatSlice";
import { fetchClient, resetClient } from "src/slices/clientSlice";
import { fetchPhases } from "src/slices/phasesSlice";
import getTheme from "src/theme/theme";

export function ChatPage() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const chat_id = searchParams.get("chat_id");
  const hideClientChat = useAppSelector((state) => state.chat.hideClientChat);
  const hasSelectedChat = useAppSelector((state) =>
    Boolean(selectSelectedChat(state)),
  );
  const chatThemeMode = useAppSelector(selectChatThemeMode);
  const chatTheme = useMemo(() => getTheme(chatThemeMode), [chatThemeMode]);
  const showFullChats =
    (useMediaQuery("(min-width:1800px)") || !hasSelectedChat) &&
    !hideClientChat;
  const chatView = useAppSelector((state) => state.chat.view);
  const clientStatus = useAppSelector((state) => state.client.status);
  const currentUserId = useAppSelector((state) => state.client.client?.user_id);

  useEffect(() => {
    if (chat_id) {
      if (
        chat_id &&
        (clientStatus === "idle" ||
          (currentUserId && chat_id !== currentUserId))
      ) {
        dispatch(openChat({ chatId: chat_id }));
        dispatch(fetchClient(chat_id));
        dispatch(fetchPhases({ userId: chat_id }));
      }
    } else {
      dispatch(resetClient());
      dispatch(closeChat());
    }
  }, [clientStatus, dispatch, chat_id, currentUserId]);

  return (
    <ThemeProvider theme={chatTheme}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          maxHeight: "-webkit-fill-available;",
          background: (theme) => theme.palette.background.default,
          overflowY: "auto",
          zIndex: 1260,
        }}
      >
        {!hasSelectedChat ? (
          <>
            <ChatListHeader condensed={!showFullChats} />
            <Box sx={{ flex: 1 }}>
              {chatView === "threads" ? (
                <ThreadsList />
              ) : chatView === "check_in" ? (
                <CheckInPanel condensed={!showFullChats} />
              ) : (
                <ChatList condensed={!showFullChats} />
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ClientChatHeader />
            <ClientChat condensed={!showFullChats} />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
