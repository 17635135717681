import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  setEquipmentWarningDialog,
  toggleEquipment,
  toggleIsInsertingTemplate,
} from "src/slices/workoutSlice";
import { WorkoutLogCard } from "../workout-logs/WorkoutLogCard";
import SectionCell from "./SectionCell";
import { WorkoutBuilderFooter } from "./WorkoutBuilderFooter";
import WorkoutBuilderHelper from "./WorkoutBuilderHelper";
import { WorkoutHeader } from "./WorkoutHeader";

export default function WorkoutBuilder() {
  const dispatch = useAppDispatch();
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const workoutStatus = useAppSelector((state) => state.workout.status);
  const workout = useAppSelector(
    (state) => state.workout.workoutNormalized?.result,
  );
  const equipmentWarningOpen = useAppSelector(
    (state) => state.workout.equipmentWarningOpen,
  );

  useHotkeys("t", () => {
    if (!isTemplate) {
      dispatch(toggleIsInsertingTemplate());
    }
  });

  const dndScrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = dndScrollableRef.current;
    if (!element) {
      return;
    }

    return autoScrollForElements({
      element,
    });
  });

  if (!workout || workoutStatus !== "succeeded") {
    return <LoadingPage message="Getting your workout ready 💪" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflowY: "hidden",
        }}
      >
        <WorkoutHeader />
        <Box
          ref={dndScrollableRef}
          sx={{ overflowY: "auto", flex: 1, maxHeight: "100%", pb: 16 }}
        >
          <WorkoutLogCard />
          <Stack sx={{ pt: 2 }}>
            {workout.sections.map((sectionId, index) => (
              <SectionCell
                key={sectionId}
                sectionId={sectionId}
                sectionIndex={index}
                isLastSection={index === workout.sections.length - 1}
              />
            ))}
          </Stack>
          <WorkoutBuilderFooter />
        </Box>
      </Box>
      <Dialog
        open={equipmentWarningOpen !== null}
        onClose={() => {
          dispatch(setEquipmentWarningDialog(null));
        }}
      >
        <DialogTitle>Check set notes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some set notes reference equipment. Make sure they are accurate when
            swapping.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setEquipmentWarningDialog(null));
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              dispatch(
                toggleEquipment({
                  exerciseId: equipmentWarningOpen!.exerciseId,
                  equipmentPickerId: equipmentWarningOpen!.equipmentPickerId,
                }),
              );
            }}
          >
            Swap anyways
          </Button>
        </DialogActions>
      </Dialog>
      <WorkoutBuilderHelper />
    </Box>
  );
}
