import CallRoundedIcon from "@mui/icons-material/CallRounded";
import {
  Button,
  type ButtonProps,
  type SxProps,
  type Theme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { cloneElement, useState, type ReactElement } from "react";
import ConfirmationDialog from "src/components/misc/ConfirmationDialog";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

type Props = {
  clientPhoneNumber: string;
  sx?: SxProps<Theme>;
  button?: ReactElement<ButtonProps>;
  onCall?: () => void;
};

export function CallClientButton({
  clientPhoneNumber,
  sx = [],
  button,
  onCall,
}: Props) {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const clonedButton = cloneElement(
    button ?? (
      <Button
        size="small"
        startIcon={<CallRoundedIcon />}
        onClick={() => {
          setConfirmationDialogOpen(true);
        }}
        sx={sx}
      >
        Call client
      </Button>
    ),
    {
      onClick: (event) => {
        button?.props.onClick?.(event);
        setConfirmationDialogOpen(true);
      },
    },
  );

  return (
    <>
      {clonedButton}
      <ConfirmationDialog
        title="Call client"
        message="Trainwell will call you then your client. The client sees trainwell's phone number and name, NEVER your number."
        open={confirmationDialogOpen}
        onClose={(didConfirm) => {
          setConfirmationDialogOpen(false);

          if (didConfirm) {
            api.phone.call({
              from_number: trainer!.phone_number,
              to_number: clientPhoneNumber,
            });

            onCall?.();

            enqueueSnackbar({
              message: "Calling client",
              variant: "success",
            });
          }
        }}
        confirmButtonText="Call client"
      />
    </>
  );
}
